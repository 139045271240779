import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "lieferservice"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ihr könnt es euch sicher schon denken:`}</p>
    <h3>{`Nachhaltigkeit ist unser Ding`}</h3>
    <p>{`Auch bei der Lieferung. Mit Fulmo als Kurier für unseren Tofu haben wir ein Partner*innenunternehmen, das unseren Tofu, die
Okarabällchen oder unseren `}{`[Mittagstisch][/mittagstisch/]`}{` sicher zu dir nach Hause, ins Büro oder wie Werkhalle bringt.
Den unser Tofu ist für alle da, auch für Menschen ohne Tagesfreizeit. `}</p>
    <h3>{`Nachhaltigkeit ist uns wirklich wichtig`}</h3>
    <p>{`Bei uns könnt ihr eure Behälter vorbeibringen und wir füllen sie euch wieder auf.
natürlich arbeiten wir auch mit Dienstleister xyz zusammen. Frag einfach nach unseren Konditionen`}</p>
    <p>{`[verwaltung][verwaltung@limeriki-tofu.de]`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      